<template>
  <div>
    <h4 class="img-uploader-title">
      {{ title }}
    </h4>
    <b-form-file
      v-model="localFile"
      :placeholder="getFileName() || 'Выберите файл'"
      drop-placeholder="Drop file here..."
    />
    <div class="my-1">
      Выбранный файл: <strong>{{ getFileName() }}</strong>
    </div>
    <div class="buttons-container">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="save-button"
        :disabled="isloading"
        @click="uploadFile"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Загрузка...' : 'Загрузить' }}
      </b-button>
      <b-button
        v-if="serverFile && !hideControlButtons"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        @click="openFile"
      >
        Посмотреть файл
      </b-button>
      <b-button
        v-if="serverFile && !hideControlButtons"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        @click="deleteFile"
      >
        Удалить файл
      </b-button>
    </div>

  </div>
</template>

<script>
import { BButton, BFormFile, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import config from '@core/app-config/api-config'
import axios from 'axios'

export default {
  name: 'FileUploader',
  components: {
    BFormFile,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    title: String,
    id: String,
    // value: Number,
    default: Object,
    hideControlButtons: Boolean,
  },
  data() {
    return {
      isloading: false,
      localFile: null,
      serverFile: null,
    }
  },
  watch: {
    id() {
      this.localFile = null
      this.serverFile = null
    },
    default() {
      this.serverFile = this.default
    },
  },
  created() {
    this.serverFile = this.default
  },
  methods: {
    getFileName() {
      if (!this.localFile && !this.serverFile) {
        return ''
      }

      if (this.localFile) {
        return this.localFile.name
      }

      if (this.serverFile) {
        return this.serverFile.name
      }
    },
    deleteFile() {
      this.serverFile = null
      this.$emit('input', null)
      this.$emit('updatePhotos', this.serverFile)
    },
    uploadFile() {
      if (!this.localFile) {
        this.makeToast('danger', 'Ошибка загрузки', 'Ошибка')
        return false
      }
      this.isloading = true
      const formData = new FormData()
      formData.append('file', this.localFile)
      formData.append('lastModified', this.localFile.lastModified)

      axios
        .post(`${config.host}/file/upload`, formData)
        .then(r => {
          this.serverFile = r.data
          this.$emit('input', this.serverFile.id)
          this.makeToast('success', 'Файл успешно загружен', 'Успешно')
          this.isloading = false
          this.$emit('updatePhotos', this.serverFile)
        })
        .catch(error => {
          this.makeToast('danger', 'Ошибка загрузки файла на сервер', 'Ошибка')
          console.log(error)
          this.isloading = false
        })
    },
    makeToast(variant = null, message = null, title = null) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      })
    },
    openFile() {
      window.open(`${this.serverFile.path}/${this.serverFile.name}`, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>
